/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch } from 'react-redux';
import { getProfile, login, getToken } from './AuthService';
import { Creators as UserCreators } from 'store/userStore';
import LoadingFullScreen from 'components/atoms/LoadingFullScreen';
import api from 'helpers/Request';
import { useQueryParams } from 'hooks/useQueryParams';

import DealerService from 'services/DealerService';
import UserServices from 'services/UserServices';

const AuthComponent = ({ children }: any) => {
  const query = useQueryParams();
  const initialize = query.get('initialize');

  const [isLogged, setLogged] = React.useState(false);

  const dispatch = useDispatch();

  const getDealerById = React.useCallback(
    async (dealerId) => {
      try {
        const response: any = await DealerService.getDealerById(dealerId);
        if (response.ok) {
          dispatch(
            UserCreators.setCurrentDealer({ ...response.data, id: dealerId }),
          );
          localStorage.setItem('dealerId', dealerId);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [dispatch],
  );

  const getUserId = async () => {
    const response: any = await UserServices.getMe();

    if (response?.ok) {
      dispatch(UserCreators.setUserData(response?.data));
      return;
    }
  };

  const setUsersStats = React.useCallback(async () => {
    let dealer = null;

    if (!!initialize) {
      dealer = { id: initialize };
    } else {
      const dealerInfosByLocalStorage: any = JSON.parse(
        localStorage.getItem('dealerId'),
      );
      if (dealerInfosByLocalStorage !== null) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        dealer = { id: dealerInfosByLocalStorage };
      }
    }
    await getUserId();
  }, [getDealerById, initialize]);

  const tryLogin = React.useCallback(async () => {
    const isAuthenticated = await login();
    if (isAuthenticated) {
      const currentUserProfile = await getProfile();
      dispatch(UserCreators.setCurrentUserData(currentUserProfile));
      api.setHeader('Authorization', `Bearer ${getToken()}`);
      await setUsersStats();
      setLogged(true);
    }
  }, [dispatch, setUsersStats]);

  React.useEffect(() => {
    tryLogin();
  }, [tryLogin]);

  if (!isLogged) {
    return <LoadingFullScreen />;
  }

  return <>{children}</>;
};

export default AuthComponent;
